import { defineComponent, inject, reactive, ref } from "vue";
import { ElInputNumber, ElNotification } from "element-plus";
import { isArray, isString } from "lodash-unified";
import { useRoute } from "vue-router";
import type { PageInput } from "./page.type";
import type { GTableColumnCtx, GTableColumnDateFormat, GTableEnumColumnCtx } from "./table.type";
import { enumMapKey, tableStateKey } from "./useTable";
import { GDialog, type GDialogInstance } from "@gejia-element-plus/components/dialog";
import { GForm, type GFormInstance, GFormItem } from "@gejia-element-plus/components/form";
import { GejiaApp } from "@gejia-element-plus/settings";
import { definePropType, useExpose, useRender, withDefineType } from "@gejia-element-plus/utils";

export default defineComponent({
	name: "GTableExportDialog",
	props: {
		/** @description 搜索参数 */
		searchParam: {
			type: definePropType<PageInput>(Object),
			required: true,
		},
		/** @description 导出Api */
		exportApi: {
			// eslint-disable-next-line no-use-before-define
			type: definePropType<
				(
					params?: PageInput & {
						pageTitle?: string;
						columns?: {
							prop: string;
							label: string;
							dateFormat: GTableColumnDateFormat;
							enum: GTableEnumColumnCtx[];
						}[];
					}
				) => Promise<void>
			>(Function),
		},
	},
	setup(props, { expose }) {
		const tableState = inject(tableStateKey);
		const enumMap = inject(enumMapKey);

		const route = useRoute();

		const gDialogRef = ref<GDialogInstance>();
		const gFormRef = ref<GFormInstance>();

		const state = reactive({
			title: "",
			maxPageIndex: 1,
		});

		const formData = reactive({
			pageIndex: 1,
			pageSize: 1000,
		});

		const formRules = reactive({
			pageIndex: [{ required: true, message: "请输入导出页码", trigger: "blur" }],
			pageSize: [{ required: true, message: "请输入导出行数", trigger: "blur" }],
			columnsIndex: [{ required: true, message: "请选择导出的列", trigger: "change" }],
		});

		const handlePageSizeChange = (value: number): void => {
			formData.pageIndex = 1;
			if (tableState.tablePagination.totalRows === 0) {
				state.maxPageIndex = 0;
			} else {
				state.maxPageIndex = Math.ceil(tableState.tablePagination.totalRows / value);
			}
		};

		const open = (): void => {
			if (!props.exportApi && !GejiaApp.table.exportApi) {
				ElNotification({
					message: "未实现表格导出方法",
					type: "error",
				});
				return;
			}
			state.title = `导出Excel选项（共 ${tableState.tablePagination.totalRows} 条）`;
			gDialogRef.value.open(async () => {
				handlePageSizeChange(formData.pageSize);
			});
		};

		const handleExportExcel = (): void => {
			gDialogRef.value.close(async () => {
				await gFormRef.value.validateScrollToField();

				let requestData: any = {};

				if (props.exportApi) {
					requestData = {
						...props.searchParam,
						...formData,
						columns: withDefineType<
							{
								prop: string;
								label: string;
								dateFormat: GTableColumnDateFormat;
								enum: GTableEnumColumnCtx[];
							}[]
						>([]),
					};
				} else {
					requestData = {
						pageTitle: route.meta?.title?.toString(),
						searchParam: {
							...props.searchParam,
							...formData,
						},
						apiUrl: tableState.responseConfig.url,
						method: tableState.responseConfig.method.toUpperCase(),
						columns: withDefineType<
							{
								prop: string;
								label: string;
								dateFormat: GTableColumnDateFormat;
								enum: GTableEnumColumnCtx[];
							}[]
						>([]),
					};
				}

				tableState.tableColumns.forEach((col: GTableColumnCtx, index: number) => {
					let enumData: GTableEnumColumnCtx[];
					if (isString(col.enum)) {
						enumData = enumMap.get(col.enum);
					} else if (isArray(col.enum)) {
						enumData = col.enum;
					}
					requestData.columns.push({
						prop: col.prop,
						label: col.label,
						dateFormat: col.dateFormat,
						enum: enumData,
					});
				});
				await (props.exportApi ?? GejiaApp.table.exportApi)(requestData);
			});
		};

		useRender(() => (
			<GDialog
				ref={gDialogRef}
				class="g-table__export-excel-dialog"
				title={state.title}
				onConfirmClick={handleExportExcel}
				showFullscreen={false}
				closeButtonText="关闭"
				confirmButtonText="导出"
				width="400px"
			>
				<GForm ref={gFormRef} model={formData} rules={formRules}>
					<GFormItem label="行数" prop="pageSize">
						<ElInputNumber
							vModel={formData.pageSize}
							min={100}
							max={
								tableState.tablePagination.totalRows >= 10000
									? 10000
									: tableState.tablePagination.totalRows < 100
										? 100
										: tableState.tablePagination.totalRows
							}
							onChange={handlePageSizeChange}
						/>
					</GFormItem>
					<GFormItem label="页码" prop="pageIndex">
						<ElInputNumber vModel={formData.pageIndex} min={1} max={state.maxPageIndex} />
					</GFormItem>
				</GForm>
			</GDialog>
		));

		return useExpose(expose, {
			/** @description 打开弹窗 */
			open,
		});
	},
});

import { computed, defineComponent, reactive } from "vue";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import { ElInput, ElPopover, ElScrollbar } from "element-plus";
import { isNull, isString } from "lodash-unified";
import { GIcon } from "@gejia-element-plus/components/icon";
import { GejiaElementPlusIconsVue } from "@gejia-element-plus/icons-vue";
import { definePropType, useExpose, useRender, withDefineType } from "@gejia-element-plus/utils";

type IconType = "ele" | "gejiaEle" | "local";

export default defineComponent({
	name: "GIconSelector",
	props: {
		/** @description v-model绑定值 */
		modelValue: String,
		/** @description 自定义图标 */
		customIcons: definePropType<string[]>(Array),
	},
	emits: {
		/** @description v-model 回调 */
		"update:modelValue": (value: string) => isString(value) || isNull(value),
		/** @description 改变 */
		change: (value: string) => isString(value) || isNull(value),
	},
	setup(props, { attrs, slots, emit, expose }) {
		const state = reactive({
			value: withDefineType<string>(),
			searchValue: withDefineType<string>(),
			iconType: withDefineType<IconType>("ele"),
			popoverVisible: false,
			iconNames: withDefineType<string[]>(Object.keys(ElementPlusIconsVue)),
			renderIconNames: computed((): string[] => {
				if (!state.searchValue) return state.iconNames;
				return state.iconNames.filter((f) => f.toLowerCase().indexOf(state.searchValue.toLowerCase()) !== -1);
			}),
		});

		const handleTabClick = (iconType: IconType): void => {
			state.iconType = iconType;
			state.iconNames = [];
			switch (iconType) {
				case "ele":
					state.iconNames = Object.keys(ElementPlusIconsVue).map((m) => `el-icon-${m}`);
					break;
				case "gejiaEle":
					state.iconNames = Object.keys(GejiaElementPlusIconsVue).map((m) => `g-icon-${m}`);
					break;
				case "local":
					state.iconNames = props.customIcons;
					break;
			}
		};

		const handleIconClick = (value: string): void => {
			state.popoverVisible = false;
			state.value = value;
			state.searchValue = "";
			emit("update:modelValue", value);
			emit("change", value);
		};

		const handleRefresh = (): void => {
			state.value = null;
			state.searchValue = null;
			emit("update:modelValue", null);
			emit("change", null);
		};

		useRender(() => (
			<ElPopover
				popperClass="g-icon-selector-popover"
				visible={state.popoverVisible}
				width="auto"
				trigger="click"
				showArrow={false}
				showAfter={0}
				hideAfter={0}
			>
				{{
					reference: () => (
						<ElInput vModel={state.searchValue} placeholder="搜索图标">
							{{
								prepend: () => <GIcon name={state.value ?? "el-icon-ChromeFilled"} />,
								append: () => <GIcon name={state.value ?? "el-icon-RefreshRight"} onClick={handleRefresh} />,
							}}
						</ElInput>
					),
					default: () => (
						<div class="g-icon-selector-popover__box">
							<div class="g-icon-selector-popover__box-header">
								<div class="g-icon-selector-popover__box-header__title">请选择图标</div>
								<div class="g-icon-selector-popover__box-header__tab">
									<span
										class={[state.iconType === "ele" ? "g-icon-selector-popover__box-header__tab-is-active" : ""]}
										title="Element Plus 图标"
										onClick={() => handleTabClick("ele")}
									>
										ele
									</span>
									<span
										class={[state.iconType === "gejiaEle" ? "g-icon-selector-popover__box-header__tab-is-active" : ""]}
										title="Gejia Element Plus 图标"
										onClick={() => handleTabClick("gejiaEle")}
									>
										gejiaEle
									</span>
									<span
										class={[state.iconType === "local" ? "g-icon-selector-popover__box-header__tab-is-active" : ""]}
										title="本地图标"
										onClick={() => handleTabClick("local")}
									>
										local
									</span>
								</div>
							</div>
							<div class="g-icon-selector-popover__box-body">
								<ElScrollbar>
									{state.renderIconNames.map((m) => (
										<div class="g-icon-selector-popover__box-body__item" title={m} onClick={() => handleIconClick(m)}>
											<GIcon name={m} />
										</div>
									))}
								</ElScrollbar>
							</div>
						</div>
					),
				}}
			</ElPopover>
		));

		return useExpose(expose, {
			iconType: state.iconType,
		});
	},
});

import type { Component } from "vue";
import { Fragment, computed, defineComponent, onMounted, reactive } from "vue";
import { Printer } from "@element-plus/icons-vue";
import { ElButton, ElDropdown, ElDropdownItem, ElDropdownMenu, ElLoading, ElMessage, buttonProps } from "element-plus";
import { isArray, isUndefined } from "lodash-unified";
import { GejiaApp } from "@gejia-element-plus/settings";
import { consoleError, consoleWarn, definePropType, useExpose, useProps, useRender, withDefineType } from "@gejia-element-plus/utils";

export default defineComponent({
	name: "GPrintButton",
	props: {
		...buttonProps,
		/** @description icon component */
		icon: {
			type: definePropType<string | Component>([String, Object, Function]),
			default: (): string | Component => Printer,
		},
		/** @description determine whether it's a circle button */
		circle: {
			type: Boolean,
			default: true,
		},
		/** @description 查询打印模板Api */
		queryPrintTemplateApi: {
			type: definePropType<(printType: number) => Promise<gePrintTemplate[]>>(Function),
		},
		/** @description 打印类型 */
		printType: {
			type: Number,
			required: true,
		},
		/** @description 订单ID */
		orderID: {
			type: definePropType<gePrintOrderIDType>([String, Number, Array]),
			required: true,
		},
	},
	setup(props, { expose }) {
		const state = reactive({
			visible: false,
			printTemplateList: withDefineType<gePrintTemplate[]>([]),
		});

		let loadingInstance: ReturnType<typeof ElLoading.service>;

		const handlePrint = async (templateID: number, orderID?: gePrintOrderIDType): Promise<void> => {
			if (!templateID) {
				consoleError("GPrintButton", "模板ID不能为空");
				ElMessage.error("模板ID不能为空");
			}

			const localOrderID = orderID ?? props.orderID;
			const orderIDList: (string | number)[] = [];

			if (isArray(localOrderID)) {
				orderIDList.push(...localOrderID);
			} else if (localOrderID) {
				orderIDList.push(localOrderID);
			}

			if (orderIDList.length === 0) {
				ElMessage.warning("请选择一个单据进行打印");
				return;
			}
			if (typeof gePrint !== "undefined" && gePrint?.Print) {
				loadingInstance = ElLoading.service({
					fullscreen: true,
					lock: true,
					text: "打印中...",
					background: "rgba(0, 0, 0, 0.7)",
				});

				for (const item of orderIDList) {
					if (!item || item === 0) {
						consoleError("GPrintButton", "打印ID不能为空或者零");
						ElMessage.error("打印ID不能为空或者零");
					} else {
						try {
							const printResStr = await gePrint.Print(templateID, item);
							const printRes = JSON.parse(printResStr) as gePrintResult;
							if (printRes.isSuccess) {
								ElMessage.success(printRes.message);
							} else {
								consoleError("GPrintButton", printRes.message);
								ElMessage.error(printRes.message);
							}
						} catch (error) {
							consoleError("GPrintButton", error);
							ElMessage.error("打印失败");
						}
					}
				}

				loadingInstance.close();
			}
		};

		/**
		 * 处理打印预览
		 * @param printType 打印类型
		 * @param orderID 订单ID
		 */
		const handlePrintPreview = (printType?: number, orderID?: number | string): void => {
			const localPrintType = printType ?? props.printType;
			if (!localPrintType) {
				consoleError("GPrintButton", "打印类型不能为空");
				ElMessage.error("打印类型不能为空");
			}

			let localOrderID = orderID ?? props.orderID;

			if (isArray(localOrderID)) {
				if (localOrderID.length > 1) {
					ElMessage.warning("只能选择一个单据进行打印预览");
					return;
				}
				localOrderID = localOrderID[0];
			}

			if (typeof gePrint !== "undefined" && gePrint?.PrintManager) {
				loadingInstance = ElLoading.service({
					fullscreen: true,
					lock: true,
					text: "打印预览中...",
					background: "rgba(0, 0, 0, 0.7)",
				});

				gePrint
					.PrintManager(localPrintType, localOrderID)
					.then((printResStr) => {
						const printRes = JSON.parse(printResStr) as gePrintResult;
						if (printRes.isSuccess) {
							ElMessage.success(printRes.message);
						} else {
							consoleError("GPrintButton", printRes.message);
							ElMessage.error(printRes.message);
						}
					})
					.catch((error) => {
						consoleError("GPrintButton", error);
						ElMessage.error("打印预览失败");
					})
					.finally(() => {
						loadingInstance.close();
					});
			}
		};

		onMounted(async () => {
			if (isUndefined(GejiaApp.print.environment)) {
				if (typeof gePrint !== "undefined" && gePrint?.GetPrintable) {
					// 检测并设置打印环境
					GejiaApp.setPrintEnvironment(await gePrint.GetPrintable());
				} else {
					consoleWarn("GPrintButton", "gePrint 打印环境不存在");
				}
			}

			// 开发环境判断
			if (GejiaApp.env === "development") {
				state.visible = true;
			} else {
				state.visible = GejiaApp.print.environment;
			}

			const queryPrintTemplateApi = props.queryPrintTemplateApi || GejiaApp.print.templateApi;
			if (!queryPrintTemplateApi) {
				consoleWarn("GPrintButton", "如果未设置全局 'GejiaApp.print.templateApi' 则 'queryPrintTemplateApi' 属性是必须的。");
			}

			if (state.visible) {
				try {
					// 查询打印模板
					state.printTemplateList = await queryPrintTemplateApi(props.printType);
				} catch (error) {
					consoleError("GPrintButton", error);
					ElMessage.error("查询打印模板配置错误");
				}
			}
		});

		const elButtonProps = useProps(props, buttonProps);

		useRender(
			() =>
				state.visible && (
					<div class="g-print-button">
						<ElDropdown trigger="click">
							{{
								default: () => <ElButton {...elButtonProps.value} title="打印" />,
								dropdown: () => (
									<ElDropdownMenu>
										<Fragment>
											{state.printTemplateList.map((item) => (
												<ElDropdownItem onClick={() => handlePrint(item.templateID)}>{item.title}</ElDropdownItem>
											))}
											<ElDropdownItem divided onClick={() => handlePrintPreview()}>
												打印预览
											</ElDropdownItem>
										</Fragment>
									</ElDropdownMenu>
								),
							}}
						</ElDropdown>
					</div>
				)
		);

		return useExpose(expose, {
			/** @description 是否可见 */
			visible: computed(() => state.visible),
			/** @description 打印模板 */
			printTemplateList: computed(() => state.printTemplateList),
			/** @description 打印 */
			print: handlePrint,
			/** @description 打印预览 */
			printPreview: handlePrintPreview,
		});
	},
});

import { RegExps } from "@gejia-element-plus/constants";
import { GejiaApp } from "@gejia-element-plus/settings";
import { Local, stringUtil } from "@gejia-element-plus/utils";

/**
 * 设备Id Key
 */
export const DEVICE_ID_KEY = "__DEVICE_ID";

/**
 * 生成设备唯一标识（不安全）
 * @description 如果存在，则返回已经生成的唯一标识
 */
export const makeIdentity = (deviceID?: string): string => {
	if (deviceID && RegExps.UUID.test(deviceID)) {
		Local.set(DEVICE_ID_KEY, deviceID);
		GejiaApp.setAppOptions({
			deviceID,
		});
		return deviceID;
	}
	// 生成浏览器唯一 UUID
	deviceID = Local.get(DEVICE_ID_KEY);
	if (deviceID && RegExps.UUID.test(deviceID)) {
		GejiaApp.setAppOptions({
			deviceID,
		});
		return deviceID;
	}
	deviceID = stringUtil.generateUUID();
	Local.set(DEVICE_ID_KEY, deviceID);
	GejiaApp.setAppOptions({
		deviceID,
	});
	return deviceID;
};

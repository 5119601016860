import type { ExtractPropTypes } from "vue";
import type { gSelectV2Emits } from "./src/selectV2";
import SelectV2, { SelectV2Props, gSelectV2Props } from "./src/selectV2";
import { withInstall } from "@gejia-element-plus/utils";

export const GSelectV2 = withInstall(SelectV2);
export default GSelectV2;

export { SelectV2Props, gSelectV2Props };

export type GSelectV2Instance = InstanceType<typeof SelectV2>;

export type GSelectV2Props = ExtractPropTypes<typeof gSelectV2Props>;

export type GSelectV2Emits = typeof gSelectV2Emits;

import { Fragment, computed, defineComponent, h, inject, resolveComponent, watch } from "vue";
import type { ComputedRef, PropType, VNode } from "vue";
import { CopyDocument } from "@element-plus/icons-vue";
import type { TableColumnCtx } from "element-plus";
import { ElIcon, ElImage, ElMessage, ElTableColumn, ElTag, ElText, dayjs, useGlobalSize } from "element-plus";
import { isNil, isNumber, isObject, isString } from "lodash-unified";
import type { GTableColumnCtx, GTableColumnDateFormat, GTableDefaultSlotsResult, GTableEnumColumnType } from "./table.type";
import { getTableDefaultSlots } from "./table.type";
import { enumMapKey, tableStateKey } from "./useTable";
import artwork from "../images/artwork.png";
import GImage from "@gejia-element-plus/components/image";
import GTag from "@gejia-element-plus/components/tag";
import { GejiaApp } from "@gejia-element-plus/settings";
import { consoleError, definePropType, makeSlots, stringUtil, tableUtil, useProps, useRender } from "@gejia-element-plus/utils";

type DefaultRow = any;

export const tableColumnProps = {
	/**
	 * @description type of the column. If set to `selection`, the column will display checkbox. If set to `index`, the column will display index of the row (staring from 1). If set to `expand`, the column will display expand icon
	 */
	type: {
		type: String,
		default: "default",
	},
	/**
	 * @description column label
	 */
	label: String,
	/**
	 * @description class name of cells in the column
	 */
	className: String,
	/**
	 * @description class name of the label of this column
	 */
	labelClassName: String,
	/**
	 * @description
	 */
	property: String,
	/**
	 * @description field name. You can also use its alias: `property`
	 */
	prop: String,
	/**
	 * @description column width
	 */
	width: {
		type: [String, Number],
		default: "",
	},
	/**
	 * @description column minimum width. Columns with `width` has a fixed width, while columns with `min-width` has a width that is distributed in proportion
	 */
	minWidth: {
		type: [String, Number],
		default: "",
	},
	/**
	 * @description render function for table header of this column
	 */
	renderHeader: Function as PropType<TableColumnCtx<DefaultRow>["renderHeader"]>,
	/**
	 * @description whether column can be sorted. Remote sorting can be done by setting this attribute to 'custom' and listening to the `sort-change` event of Table
	 */
	sortable: {
		type: [Boolean, String],
		default: false,
	},
	/**
	 * @description sorting method, works when `sortable` is `true`. Should return a number, just like Array.sort
	 */
	sortMethod: Function as PropType<TableColumnCtx<DefaultRow>["sortMethod"]>,
	/**
	 * @description specify which property to sort by, works when `sortable` is `true` and `sort-method` is `undefined`. If set to an Array, the column will sequentially sort by the next property if the previous one is equal
	 */
	sortBy: [String, Function, Array] as PropType<TableColumnCtx<DefaultRow>["sortBy"]>,
	/**
	 * @description whether column width can be resized, works when `border` of `el-table` is `true`
	 */
	resizable: {
		type: Boolean,
		default: true,
	},
	/**
	 * @description column's key. If you need to use the filter-change event, you need this attribute to identify which column is being filtered
	 */
	columnKey: String,
	/**
	 * @description alignment, the value should be 'left' \/ 'center' \/ 'right'
	 */
	align: String,
	/**
	 * @description alignment of the table header. If omitted, the value of the above `align` attribute will be applied, the value should be 'left' \/ 'center' \/ 'right'
	 */
	headerAlign: String,
	/**
	 * @description whether to hide extra content and show them in a tooltip when hovering on the cell
	 */
	showOverflowTooltip: {
		type: [Boolean, Object] as PropType<TableColumnCtx<DefaultRow>["showOverflowTooltip"]>,
		default: undefined,
	},
	/**
	 * @description whether column is fixed at left / right. Will be fixed at left if `true`
	 */
	fixed: [Boolean, String],
	/**
	 * @description function that formats cell content
	 */
	formatter: Function as PropType<TableColumnCtx<DefaultRow>["formatter"]>,
	/**
	 * @description function that determines if a certain row can be selected, works when `type` is 'selection'
	 */
	selectable: Function as PropType<TableColumnCtx<DefaultRow>["selectable"]>,
	/**
	 * @description whether to reserve selection after data refreshing, works when `type` is 'selection'. Note that `row-key` is required for this to work
	 */
	reserveSelection: Boolean,
	/**
	 * @description data filtering method. If `filter-multiple` is on, this method will be called multiple times for each row, and a row will display if one of the calls returns `true`
	 */
	filterMethod: Function as PropType<TableColumnCtx<DefaultRow>["filterMethod"]>,
	/**
	 * @description filter value for selected data, might be useful when table header is rendered with `render-header`
	 */
	filteredValue: Array as PropType<TableColumnCtx<DefaultRow>["filteredValue"]>,
	/**
	 * @description an array of data filtering options. For each element in this array, `text` and `value` are required
	 */
	filters: Array as PropType<TableColumnCtx<DefaultRow>["filters"]>,
	/**
	 * @description placement for the filter dropdown
	 */
	filterPlacement: String,
	/**
	 * @description whether data filtering supports multiple options
	 */
	filterMultiple: {
		type: Boolean,
		default: true,
	},
	/**
	 * @description className for the filter dropdown
	 */
	filterClassName: String,
	/**
	 * @description customize indices for each row, works on columns with `type=index`
	 */
	index: [Number, Function] as PropType<TableColumnCtx<DefaultRow>["index"]>,
	/**
	 * @description the order of the sorting strategies used when sorting the data, works when `sortable` is `true`. Accepts an array, as the user clicks on the header, the column is sorted in order of the elements in the array
	 */
	sortOrders: {
		type: Array as PropType<TableColumnCtx<DefaultRow>["sortOrders"]>,
		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		default: () => {
			return ["ascending", "descending", null];
		},
		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		validator: (val: TableColumnCtx<unknown>["sortOrders"]) => {
			return val.every((order: string) => ["ascending", "descending", null].includes(order));
		},
	},
};

type GTableColumnSlotsResult = {
	/** @description slots为表格内容的时候才会返回 */
	row?: any;
	/** @description slot为表头内容的时候返回 'TableColumnCtx<any>' 否则返回 'GTableColumnCtx' */
	column?: TableColumnCtx<any> | GTableColumnCtx;
	$index?: number;
};

type GTableColumnDefaultSlots = {
	[key: string]: GTableDefaultSlotsResult & GTableColumnSlotsResult;
};

type GTableColumnSlots = {
	/** @description 默认内容插槽 */
	default: GTableDefaultSlotsResult & { row: any; column: GTableColumnCtx; $index: number };
	/** @description 自定义表头的内容 */
	header: GTableDefaultSlotsResult & { column: GTableColumnCtx; $index: number };
	/** @description 自定义 filter 图标 */
	filterIcon: GTableDefaultSlotsResult & { filterOpened: boolean };
} & GTableColumnDefaultSlots;

export default defineComponent({
	name: "GTableColumn",
	props: {
		...tableColumnProps,
		/**
		 * @description column width
		 */
		width: {
			type: [String, Number],
			default: "auto",
		},
		/** @description alignment, the value should be 'left' \/ 'center' \/ 'right' */
		align: {
			type: String,
			default: "left",
		},
		/** @description alignment of the table header. If omitted, the value of the above `align` attribute will be applied, the value should be 'left' \/ 'center' \/ 'right' */
		headerAlign: {
			type: String,
			default: "left",
		},
		/** @description 是否显示在表格当中 */
		show: Boolean,
		/** @description 小页面的宽度，如果为空，则继承默认宽度 */
		smallWidth: {
			type: [String, Number],
		},
		/** @description 自适应宽度 */
		autoWidth: Boolean,
		/** @description 插槽名称 */
		slot: String,
		/** @description 表格头部插槽名称 */
		headerSlot: String,
		/** @description 自定义表头内容渲染（tsx语法） */
		headerRender: {
			type: definePropType<({ column, $index }: { column: TableColumnCtx<any>; $index: number } & GTableDefaultSlotsResult) => VNode[]>(
				Function
			),
		},
		/** @description 自定义单元格内容渲染（tsx语法） */
		render: {
			type: definePropType<
				({ row, column, $index }: { row: any; column: GTableColumnCtx; $index: number } & GTableDefaultSlotsResult) => VNode[]
			>(Function),
		},
		/** @description 多级表头 */
		// eslint-disable-next-line vue/prop-name-casing
		_children: {
			type: definePropType<GTableColumnCtx[]>(Array),
		},
		/** @description 复制 */
		copy: Boolean,
		/** @description 是否为 Link Button */
		link: Boolean,
		/** @description 合并行字段 */
		spanProp: String,
		/** @description Link 按钮的点击事件，优先级最高 */
		click: {
			type: definePropType<({ row, $index }: { row: any; $index?: number }) => void>(Function),
		},
		/** @description 点击Emits事件回调 */
		clickEmit: String,
		/** @description 显示时间格式化字符串 */
		dateFix: Boolean,
		/** @description 显示在页面中的日期格式 */
		dateFormat: {
			type: definePropType<GTableColumnDateFormat>(String),
		},
		/** @description 是否是标签展示 */
		tag: Boolean,
		/** @description 枚举类型（渲染值的字典） */
		enum: {
			type: definePropType<GTableEnumColumnType>([String, Array, Function]),
		},
		/** @description 枚举字典，字段名称 */
		fieldNames: {
			type: definePropType<{ label: string; value: string }>(Object),
		},
		/** @description 数据删除字段，如果为 true 会显示遮罩层 */
		dataDeleteField: String,
		/** @description 制单信息计算 */
		submitInfoField: {
			type: definePropType<{ submitClerkName?: string; submitTime?: string }>(Object),
			default: () => ({
				submitClerkName: "submitClerkName",
				submitTime: "submitTime",
			}),
		},
		/** @description 商品字段 */
		productField: {
			type: definePropType<{ productName?: string; productSpecName?: string; productBarCode?: string; productUnit?: string }>(Object),
			default: () => ({
				productName: "productName",
				productSpecName: "productSpecName",
				productBarCode: "productBarCode",
				productUnit: "productOrderUnit",
			}),
		},
		/** @description 货位字段 */
		locationField: {
			type: definePropType<{ locationName?: string; locationCode?: string; locationType?: string; locationTypeKey?: string }>(Object),
			default: () => ({
				locationName: "locationName",
				locationCode: "locationCode",
				locationType: "locationType",
				locationTypeKey: "LocationTypeEnum",
			}),
		},
		/** @description 打印字段 */
		printField: {
			type: definePropType<{ printTimes?: string; printClerkName?: string; lastPrintTime?: string }>(Object),
			default: () => ({
				printTimes: "printTimes",
				printClerkName: "printClerkName",
				lastPrintTime: "lastPrintTime",
			}),
		},
	},
	emits: {
		/** @description 图片预览 */
		imagePreview: (url: string): boolean => isString(url),
		/** @description 自定义单元格点击事件 */
		customCellClick: (emitName: string, { row, column, $index }: { row: any; column: GTableColumnCtx; $index: number }): boolean =>
			isString(emitName) && isObject(row) && isObject(column) && isNumber($index),
	},
	slots: makeSlots<GTableColumnSlots>(),
	setup(props, { slots, emit }) {
		const _globalSize = useGlobalSize();
		const tableState = inject(tableStateKey);
		const enumMap = inject(enumMapKey);

		const columnCtx = computed(() => props as unknown as GTableColumnCtx);

		const renderCellData = ({ row }: { row: any }): any => {
			let enumKey = props.prop;
			if (isString(props.enum)) {
				enumKey = props.enum;
			}
			const enumData = enumMap.get(enumKey);
			if (enumData) {
				return tableUtil.filterEnum(
					tableUtil.handleRowAccordingToProp(row, props.prop),
					enumData,
					props.fieldNames ?? { label: "label", value: "value" }
				);
			} else {
				return tableUtil.formatValue(tableUtil.handleRowAccordingToProp(row, props.prop));
			}
		};

		const getTagType = ({ row }: { row: any }): any => {
			let enumKey = props.prop;
			if (isString(props.enum)) {
				enumKey = props.enum;
			}
			const enumData = enumMap.get(enumKey);
			return tableUtil.filterEnum(
				tableUtil.handleRowAccordingToProp(row, props.prop),
				enumData,
				props.fieldNames ?? { label: "label", value: "value" },
				"tag"
			) as any;
		};

		const getWidth = (defAttr: string): string | number => {
			if (props.autoWidth) {
				return computed(() => {
					const findInfo = tableState.autoColumnWidth.find((f) => f.prop === props.prop);
					if (findInfo) {
						return `${findInfo.width}px`;
					}
					return "auto";
				}).value;
			}
			if (_globalSize.value === "small") {
				return props.smallWidth ?? props.width ?? props.minWidth ?? defAttr ?? "auto";
			}
			return props.width ?? props.minWidth ?? defAttr ?? "auto";
		};

		const autoWidthHeaderRender = (el: VNode[]): VNode[] => {
			if (props.autoWidth) {
				return (
					<div class={["g-table__auto-width-column__cell-header", `__g-table__auto-width-column__cell-header__${props.prop}`]}>{el}</div>
				);
			} else {
				return el;
			}
		};

		const headerRender = ({ column, $index }: { column: TableColumnCtx<any>; $index: number }): VNode[] => {
			if (props.headerRender) {
				return autoWidthHeaderRender(props.headerRender({ column, $index, ...getTableDefaultSlots(tableState) }));
			} else if (props.headerSlot) {
				return autoWidthHeaderRender(
					slots[props.headerSlot] && slots[props.headerSlot]({ column, $index, ...getTableDefaultSlots(tableState) })
				);
			} else {
				return autoWidthHeaderRender(<span>{props.label}</span>);
			}
		};

		const autoWidthRender = (el: VNode[]): VNode[] => {
			if (props.autoWidth) {
				return <div class={["g-table__auto-width-column__cell", `__g-table__auto-width-column__cell__${props.prop}`]}>{el}</div>;
			} else {
				return el;
			}
		};

		const handleCopyClick = async (value): Promise<void> => {
			try {
				await stringUtil.copy(String(value));
				ElMessage({
					type: "success",
					message: "复制成功",
				});
			} catch (error) {
				consoleError("GTableColumn", error);
				ElMessage({
					type: "error",
					message: "复制失败",
				});
			}
		};

		const copyRender = (value, copy?: boolean): VNode[] => {
			return (
				(props.copy || copy) &&
				value && (
					<ElIcon class="g__copy-icon" title="复制" onClick={() => handleCopyClick(value)}>
						<CopyDocument />
					</ElIcon>
				)
			);
		};

		const formatterRender = (row: any, column: TableColumnCtx<any>, cellValue: any, index: number): any => {
			if (column.formatter) {
				return column.formatter(row, column, cellValue, index);
			} else {
				return cellValue;
			}
		};

		const defaultRender = ({ row, column, $index }: { row: any; column: TableColumnCtx<any>; $index: number }): VNode[] => {
			if (props.type === "submitInfo") {
				const submitTime = row[props.submitInfoField?.submitTime ?? "submitTime"];
				return (
					<Fragment>
						<div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" title={submitTime}>
							<span style="margin-right: 5px;">{row[props.submitInfoField?.submitClerkName ?? "submitClerkName"]}</span>
							<span>{submitTime}</span>
						</div>
						<ElTag type="info" round effect="light" size="small">
							{tableUtil.dateTimeFix(String(submitTime))}
						</ElTag>
					</Fragment>
				);
			} else if (props.type === "product") {
				// 单位判断
				let unit = row[props.productField?.productUnit ?? "productOrderUnit"];
				let unitDesc = false;
				if (!unit) {
					unit = row["productUnit"] ?? row["unit"];
					if (!unit) {
						unitDesc = true;
						unit = row["stockRelationDesc"];
					}
				}
				const renderValue = row[props.productField?.productName ?? "productName"];
				const productBarCode =
					row[props.productField?.productBarCode ?? "productBarCode"] ??
					row["productBarCode"] ??
					row["productSupBarCode"] ??
					row["productBigBarCode"] ??
					row["productOwnCode"] ??
					row["productOuterNO"] ??
					row["productCode"];
				return (
					<Fragment>
						<div style="display: flex">
							{copyRender(renderValue, true)}
							{renderValue && props.link ? (
								<ElText
									class={"el-link is-underline g-table__link-column__text"}
									style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
									title={renderValue}
									onClick={() => {
										// 数据删除拦截点击
										if (props.dataDeleteField && row[props.dataDeleteField] === true) return;
										if (props.click) {
											props.click({ row, $index });
										} else {
											emit("customCellClick", props.clickEmit, {
												row,
												column: columnCtx.value,
												$index,
											});
										}
									}}
								>
									{renderValue}
								</ElText>
							) : (
								<b style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{renderValue}</b>
							)}
						</div>
						<div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" title={productBarCode}>
							<span style="margin-right: 5px;">
								<span style="color: var(--el-color-info);">规格：</span>
								{row[props.productField?.productSpecName ?? "productSpecName"]}
							</span>
							{!unitDesc && (
								<span style="margin-right: 5px;">
									<span style="color: var(--el-color-info);">单位：</span>
									{unit}
								</span>
							)}
							<span>
								<span style="color: var(--el-color-info);">条码：</span>
								{productBarCode}
							</span>
						</div>
						{unitDesc && (
							<span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" title={unit}>
								<span style="color: var(--el-color-info);">库存关系：</span>
								{unit}
							</span>
						)}
					</Fragment>
				);
			} else if (props.type === "location") {
				const renderValue = row[props.locationField?.locationName ?? "locationName"];
				const locationCode = row[props.locationField?.locationCode ?? "locationCode"];
				const locationType = row[props.locationField?.locationType ?? "locationType"];
				return (
					<Fragment>
						<div style="display: flex">
							{copyRender(renderValue, true)}
							{renderValue && props.link ? (
								<ElText
									class={"el-link is-underline g-table__link-column__text"}
									style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
									title={renderValue}
									onClick={() => {
										// 数据删除拦截点击
										if (props.dataDeleteField && row[props.dataDeleteField] === true) return;
										if (props.click) {
											props.click({ row, $index });
										} else {
											emit("customCellClick", props.clickEmit, {
												row,
												column: columnCtx.value,
												$index,
											});
										}
									}}
								>
									{renderValue}
								</ElText>
							) : (
								<b style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{renderValue}</b>
							)}
						</div>
						<div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" title={locationCode}>
							{locationCode && (
								<span style="margin-right: 5px;">
									<span style="color: var(--el-color-info);">编码：</span>
									{locationCode}
								</span>
							)}
							{!isNil(locationType) && locationType > 0 && (
								<span>
									<span style="color: var(--el-color-info);">类型：</span>
									<GTag name="LocationTypeEnum" value={row[props.locationField?.locationType ?? "locationType"]} />
								</span>
							)}
						</div>
					</Fragment>
				);
			} else if (props.type === "print") {
				const printTimes = row[props.printField?.printTimes ?? "printTimes"];
				const printClerkName = row[props.printField?.printClerkName ?? "printClerkName"];
				const lastPrintTime = row[props.printField?.lastPrintTime ?? "lastPrintTime"];
				return Number(printTimes) > 0 ? (
					<Fragment>
						<div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" title={lastPrintTime}>
							{printClerkName && <span style="margin-right: 5px; color: var(--el-color-info);">{printClerkName}</span>}
							{lastPrintTime && <span style="color: var(--el-color-info);">{lastPrintTime}</span>}
						</div>
						<div
							style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
							title={`${row[props.printField?.printTimes ?? "printTimes"]}次`}
						>
							<span style="margin-right: 5px; color: var(--el-color-info);">
								打印 <span style="color: var(--el-color-danger);">{row[props.printField?.printTimes ?? "printTimes"]}</span> 次
							</span>
							<ElTag type="info" round effect="light" size="small">
								{tableUtil.dateTimeFix(String(lastPrintTime))}
							</ElTag>
						</div>
					</Fragment>
				) : (
					<span style="color: var(--el-color-info);">未打印</span>
				);
			} else if (props.tag) {
				const renderValue = formatterRender(row, column, renderCellData({ row }), $index);
				return autoWidthRender(
					<Fragment>
						{copyRender(renderValue)}
						{renderValue ? <ElTag type={getTagType({ row })}>{renderValue}</ElTag> : null}
					</Fragment>
				);
			} else if (props.type === "date" || props.type === "time" || props.type === "dateTime") {
				let dateFormat;
				switch (props.type) {
					case "date":
						dateFormat = "YYYY-MM-DD";
						break;
					case "time":
						dateFormat = "HH:mm:ss";
						break;
					case "dateTime":
						dateFormat = "YYYY-MM-DD HH:mm:ss";
						break;
				}
				const renderValue = row[props.prop]
					? formatterRender(row, column, dayjs(row[props.prop]).format(props.dateFormat ?? dateFormat), $index)
					: null;
				return autoWidthRender(
					<Fragment>
						{copyRender(renderValue)}
						{renderValue}
						{props.dateFix && renderValue && (
							<Fragment>
								<br />
								<ElTag type="info" round effect="light">
									{tableUtil.dateTimeFix(String(renderValue))}
								</ElTag>
							</Fragment>
						)}
					</Fragment>
				);
			} else if (props.link) {
				const renderValue = formatterRender(row, column, row[props.prop], $index);
				return autoWidthRender(
					<Fragment>
						{copyRender(renderValue)}
						{renderValue && (
							<ElText
								class={"el-link is-underline g-table__link-column__text"}
								onClick={() => {
									// 数据删除拦截点击
									if (props.dataDeleteField && row[props.dataDeleteField] === true) return;
									if (props.click) {
										props.click({ row, $index });
									} else {
										emit("customCellClick", props.clickEmit, {
											row,
											column: columnCtx.value,
											$index,
										});
									}
								}}
							>
								{renderValue}
							</ElText>
						)}
					</Fragment>
				);
			} else if (props.render) {
				return autoWidthRender(props.render({ row, column: columnCtx.value, $index, ...getTableDefaultSlots(tableState) }));
			} else if (props.slot) {
				return autoWidthRender(
					slots[props.slot] && slots[props.slot]({ row, column: columnCtx.value, $index, ...getTableDefaultSlots(tableState) })
				);
			} else {
				const renderValue = formatterRender(row, column, row[props.prop], $index);
				return autoWidthRender(
					<Fragment>
						{copyRender(renderValue)}
						{renderValue}
					</Fragment>
				);
			}
		};

		let elTableColumnProps: ComputedRef<TableColumnCtx<any>> = useProps(props, tableColumnProps, [
			"type",
			"width",
			"minWidth",
			"sortable",
			"sortOrders",
			"resizable",
			"showOverflowTooltip",
		]) as unknown as ComputedRef<TableColumnCtx<any>>;

		watch(
			() => props,
			() => {
				elTableColumnProps = useProps(props, tableColumnProps, [
					"type",
					"minWidth",
					"sortable",
					"sortOrders",
					"resizable",
					"showOverflowTooltip",
				]) as unknown as ComputedRef<TableColumnCtx<any>>;
			}
		);

		useRender(() => (
			<Fragment>
				{
					// 如果有配置多级表头的数据，则递归该组件
					props._children?.length ? (
						<ElTableColumn
							{...elTableColumnProps.value}
							minWidth={getWidth("auto")}
							sortable={props.sortable ?? "custom"}
							sortOrders={props.sortOrders ?? ["descending", "ascending", null]}
							resizable={props.resizable && !props.autoWidth}
							showOverflowTooltip={(props.showOverflowTooltip ?? true) && !props.autoWidth && !props.type}
						>
							{{
								header: ({ column, $index }: { column: TableColumnCtx<any>; $index: number }) => headerRender({ column, $index }),
								default: () =>
									props._children.map((col: GTableColumnCtx) =>
										h(resolveComponent("GTableColumn"), {
											...col,
											vSlots: slots,
										})
									),
							}}
						</ElTableColumn>
					) : props.type === "image" ? (
						<ElTableColumn
							{...elTableColumnProps.value}
							align="center"
							className="g-table__image-column"
							minWidth="50px"
							sortable={false}
							resizable={false}
							showOverflowTooltip={false}
						>
							{{
								header: ({ column, $index }: { column: TableColumnCtx<any>; $index: number }) => headerRender({ column, $index }),
								default: ({ row }: { row: any; column: TableColumnCtx<any>; $index: number }) =>
									row[props.prop] &&
									(GejiaApp.table.hideImage ? (
										<ElImage
											class="g-image"
											lazy
											src={artwork}
											fit="cover"
											previewSrcList={[row[props.prop]]}
											closeOnPressEscape
											hideOnClickModal
											previewTeleported
										/>
									) : (
										<GImage lazy src={row[props.prop]} fit="cover" thumb />
									)),
							}}
						</ElTableColumn>
					) : (
						// 其他正常的列
						<ElTableColumn
							{...elTableColumnProps.value}
							minWidth={getWidth("auto")}
							sortable={props.sortable ?? "custom"}
							sortOrders={props.sortOrders ?? ["descending", "ascending", null]}
							resizable={props.resizable && !props.autoWidth}
							showOverflowTooltip={(props.showOverflowTooltip ?? true) && !props.autoWidth && !props.type}
						>
							{{
								header: ({ column, $index }: { column: TableColumnCtx<any>; $index: number }) => headerRender({ column, $index }),
								default: ({ row, column, $index }: { row: any; column: TableColumnCtx<any>; $index: number }) =>
									defaultRender({ row, column, $index }),
							}}
						</ElTableColumn>
					)
				}
			</Fragment>
		));
	},
});

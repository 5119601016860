import type { PropType } from "vue";
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { Delete, Search } from "@element-plus/icons-vue";
import { useVModel } from "@vueuse/core";
import type { TableProps } from "element-plus";
import { ElButton, ElButtonGroup, ElInput } from "element-plus";
import { isFunction, isNull, isNumber, isString } from "lodash-unified";
import type { GDialogInstance } from "@gejia-element-plus/components/dialog";
import { GDialog } from "@gejia-element-plus/components/dialog";
import type { GTableColumnCtx, GTableInstance, PageInput, PageResult } from "@gejia-element-plus/components/table";
import { GTable } from "@gejia-element-plus/components/table";
import { definePropType, useExpose, useRender, withDefineType } from "@gejia-element-plus/utils";

export default defineComponent({
	name: "GInputDialogPage",
	props: {
		/** @description key of row data, used for optimizing rendering. Required if `reserve-selection` is on or display tree data. When its type is String, multi-level access is supported, e.g. `user.info.id`, but `user.info[0].id` is not supported, in which case `Function` should be used */
		rowKey: {
			type: [String, Function] as PropType<TableProps<any>["rowKey"]>,
			default: "id",
		},
		/** @description v-model绑定值 */
		modelValue: [String, Number],
		/** @description v-model:label绑定值 */
		label: String,
		/** @description 输入框占位文本 */
		placeholder: {
			type: String,
			default: "请选择",
		},
		/** @description 禁用 */
		disabled: Boolean,
		/** @description 标题 */
		title: String,
		/** @description 表格Key */
		tableKey: String,
		/** @description 列配置 */
		columns: {
			type: definePropType<GTableColumnCtx[] | false>([Array, Boolean]),
			// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
			default: () => false,
		},
		/** @description 请求api */
		requestApi: {
			// eslint-disable-next-line no-use-before-define
			type: definePropType<(params?: PageInput) => Promise<PageResult | any[]>>(Function),
		},
		/** 初始化参数 */
		initParam: definePropType<any>(Object),
		/** @description 显示文本 Key */
		labelKey: {
			type: String,
			default: "name",
		},
	},
	emits: {
		/** @description v-model 回调 */
		"update:modelValue": (value: string | number) => isString(value) || isNumber(value) || isNull(value),
		/** @description v-model:label 回调 */
		"update:label": (value: string) => isString(value) || isNull(value),
		/** @description 改变 */
		change: (value: string | number) => isString(value) || isNumber(value) || isNull(value),
	},
	setup(props, { emit, expose }) {
		const selectedLabel = useVModel(props, "label", emit, { passive: true });

		const state = reactive({
			value: withDefineType<string | number>(),
			selectionRow: withDefineType<unknown>(),
		});

		const gDialogRef = ref<GDialogInstance>();
		const gTableRef = ref<GTableInstance>();

		const handleDeleteClick = (): void => {
			state.value = null;
			selectedLabel.value = null;
			emit("update:modelValue", null);
			emit("change", null);
		};

		const handleSearchClick = (): void => {
			gDialogRef.value.open(() => {
				if (state.selectionRow) {
					// 判断当前行是否选中
					const rowSelected = gTableRef.value.selectedListIds.includes(
						isFunction(props.rowKey) ? props.rowKey(state.selectionRow) : state.selectionRow[props.rowKey]
					);
					if (!rowSelected) {
						gTableRef.value.toggleRowSelection(state.selectionRow);
					}
				}
			});
		};

		const handleDialogClick = (): void => {
			gDialogRef.value.close(() => {
				if (gTableRef.value.selected) {
					const selectedData = gTableRef.value.selectedList[0];
					emit("update:modelValue", selectedData[isFunction(props.rowKey) ? props.rowKey(selectedData) : selectedData[props.rowKey]]);
					selectedLabel.value = selectedData[props.labelKey];
					emit("change", selectedData);
				} else {
					emit("update:modelValue", null);
					selectedLabel.value = null;
					emit("change", null);
				}
			});
		};

		const handleTableRowDblclick = (row: any): void => {
			gTableRef.value.clearSelection();
			gTableRef.value.toggleRowSelection(row);
			state.selectionRow = row;
			handleDialogClick();
		};

		watch(
			() => props.modelValue,
			(newValue) => {
				state.value = newValue;
			},
			{
				immediate: true,
			}
		);

		// TODO:这里的GTable有问题

		useRender(() => (
			<div class="g-input-dialog-page">
				<ElInput vModel={selectedLabel.value} placeholder={props.placeholder} disabled={props.disabled} readonly>
					{{
						append: () => (
							<ElButtonGroup>
								<ElButton disabled={props.disabled} icon={Delete} onClick={handleDeleteClick} />
								<ElButton disabled={props.disabled} icon={Search} onClick={handleSearchClick} />
							</ElButtonGroup>
						),
					}}
				</ElInput>
				<GDialog
					ref={gDialogRef}
					style="--height: 70%;"
					width="50%"
					title={props.title}
					fillHeight
					disabledConfirmButton={!gTableRef.value?.selected}
					onConfirmClick={handleDialogClick}
				>
					<GTable
						ref={gTableRef}
						rowKey={props.rowKey}
						tableKey={props.tableKey}
						columns={props.columns}
						requestApi={props.requestApi}
						initParam={props.initParam}
						single
						rowClickSelection
						hideSearchTime
						exportBtn={false}
						columnSettingBtn={false}
						onRowDblclick={handleTableRowDblclick}
					/>
				</GDialog>
			</div>
		));

		return useExpose(expose, {
			/** @description 选择行数据 */
			selectionRow: computed(() => state.selectionRow),
			/** @description 打开选择器弹窗 */
			open: handleSearchClick,
			/** @description 清除选择 */
			clear: handleDeleteClick,
		});
	},
});
